import { Observer } from 'shared/services/observer-manager/observer.service';
import { MpCompareProductsService } from 'services/compare-products/mpCompareProducts.service';

export function useDeleteFromCompareProducts(handler: (modelIds?: Array<number>) => void | Promise<void>): void {
  const deleteFromCompareObserver = ref<Observer<Array<number>> | undefined>();
  const mpCompareProductsServer = inject<MpCompareProductsService>(MpCompareProductsService.getServiceName());

  onBeforeMount(() => {
    deleteFromCompareObserver.value = mpCompareProductsServer?.subscribeOnDeleteProducts(handler);
  });

  onBeforeUnmount(() => {
    if (deleteFromCompareObserver.value) {
      mpCompareProductsServer?.unsubscribeOnDeleteProducts(deleteFromCompareObserver.value);
    }
  });
}
